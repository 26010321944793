:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.announcement{
    padding: 1.5rem 6rem;
    display: flex;
}
.content-heading{
    color: var(--primary-color);
    text-decoration: underline;
    text-decoration-color: #2b2c282e;
    text-align: start;
    padding-bottom: .2rem;
}
.right-container{
    width: 100%;
    margin-right: 6rem;
}
.news-container{
    height: 37rem;
    overflow: scroll;
}

.news-headline{
    font-size: medium;
    color: var(--primary-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news-headline h5{
    margin-bottom: 0;
}

.news-content{
    display: flex;
    /* width: 50%; */
    padding: 0.5rem 0rem;
}

.news-img img{
    width: 11rem;
    padding-right: .5rem;
}
.news-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news-text p{
    color: black;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news-text p:hover{
    text-decoration: underline;
    cursor: pointer;
}

/* announcement section */
.left-container{

}
.announcements{
    padding-bottom: 1rem;
}
.announcements-content{
    padding: .3rem 0rem;
}
.announcements-content p{
    padding-bottom: .4rem;
    text-align: justify;
    margin-bottom: 0;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}
/* .announcements-content span{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--hover-color);
} */
.underline-box{
    background: #2b2c282e;
    height: 0.2rem;
    border-radius: 100%;
}

.announcement-container{
    height: 17rem;
    width: 24rem;

    overflow: scroll;
}



/* event calendar */
.event-content{
    padding: .3rem 0rem;
}
.event-text{
    display: flex;
    padding-bottom: .4rem;
}
.event-text p{
    margin-bottom: 0;
}
.event-headline h6{
    margin-bottom: 0.1rem;
    color: var(--primary-color);
}
.png{
    font-size: 1.5rem;
    color: var(--primary-color);
}
.event-scroll{
    height: 16rem;
    overflow: scroll;
}

@media screen and (max-width: 1024px){
    .announcement{
        padding: 1.5rem 2rem;
        flex-direction: column;
    }
    .right-container{
        /* width: 50%; */
        padding-right: 0rem;
    }

    .space{
        padding-bottom: 2rem;
    }
    .content-heading{
       
        padding-bottom: .4rem;
    }
    .announcement-container{
        width: auto;
    }
    .news-headline{
        font-size: small;
        display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
    .news-text p{
        margin-top: -.4rem;
        font-size: small;
        -webkit-line-clamp: 2;
    }
    .news-img img{
        width: 6rem;
        
    }
}