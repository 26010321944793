:root {
    --primary-color: #339989;
    --hover-color: rgb(255, 91, 39);
    --white-color: white;
    --black-color: #2b2c28;
    --gradient: linear-gradient(rgb(255, 91, 39), rgb(255, 151, 75));
  }
  
  .container {
    padding: 0.5rem 2rem;
    text-align: center;
  }
  
  .box {
    padding: 0.5rem 0rem;
  }
  
  .box img {
    width: auto;
    height: 12rem;
    margin-left: 3rem;
    border-radius: 0.5rem;
  }
  
  body.active-modal {
    overflow-y: hidden;
  }
  
  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Ensure modal container is on top of everything */
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.557);
    z-index: 1000; /* Ensure overlay is below modal container */
  }
  
  .modal-contents {
    background: none;
  }
  
  .modal img {
    position: relative;
    z-index: 1002; /* Ensure image is on top of everything */
    width: auto;
    height: 40rem;
    border-radius: 1rem;
    border: .3rem solid white;
  }
  
  @media screen and (max-width: 768px) {
    .modal img {
      width: 20rem;
      height: auto;
    }
  }
  