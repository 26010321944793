:root{
    --primary-color: #339989;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: #2B2C28;
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.mission{
    .heading{
        text-align: center;
    }
    text-align: justify;
    padding: 0.5rem 6rem;
}

@media screen and (max-width: 768px){
    .mission{
        font-size: small;
        padding: 0.5rem 2rem;
    }  
}