/* styles.css */

.login-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  
  
  /* .heading {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1a202c;
  } */
  
  .loginform-container {
    margin-top: 2.5rem;
    max-width: 24rem;
    width: 100%;
  }
  
  .loginform {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 1.5rem;
  }
  
  .label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1a202c;
  }
  
  .input {
    width: 100%;
    border-radius: 0.375rem;
    border: none;
    padding: 0.375rem;
    color: #1a202c;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  .password-label-group {
    display: flex;
    justify-content: space-between;
  }
  
  .forgot-password {
    font-size: 0.875rem;
    font-weight: 500;
    color: #7e3af2;
  }
  
  .submit-button {
    width: 100%;
    border: none;
    border-radius: 0.375rem;
    padding: 0.375rem;
    background-color: #7e3af2;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  .submit-button:hover {
    background-color: #6d28d9;
  }
  
  .signup-link {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 0.875rem;
    color: #cbd5e0;
  }
  
  .signup-link-text {
    font-weight: 600;
    color: #7e3af2;
  }
  
  .signup-link-text:hover {
    color: #6d28d9;
  }
  