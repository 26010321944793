:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.ant-carousel .slick-dots li button {
    background: var(--primary-color);
    opacity: 0.4;
}
.ant-carousel .slick-dots li.slick-active button{
    background: var(--primary-color);
}
.stories{
    
    padding: 0.5rem 5.5rem;
}
.endButton{
    position: absolute;
    right: 0;
}
.startButton{
    display: flex;
    

}


.stories-content{
    padding: 1rem .5rem; 
    width: 80%;
}
.profile{
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: .5rem;
    background: rgb(245, 245, 245);
}
.profile img{
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
}
.profile-content{
    padding-left: 1rem;
}
.profile-content p {
    color: var(--primary-color);
}
.profile-content h5 {
    color: black;
}
.profile-content h5:hover {
    text-decoration: underline;
}
/* p{
    text-align: justify;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
} */
.profile-content h5{
    margin-bottom: 0;
    line-height: 1.2;
}


@media screen and (max-width: 1024px) {
    .stories{
        padding:0rem 2rem;
    }
    .profile-content h5{
        font-size: medium;
    }
}
