@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Kanit:wght@400;500;600;700&family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&family=Poppins:wght@400;500;600;700&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  /* transition: .3s ease; */
  
}

:root{
  --primary-color: #5296a5;
  --hover-color: #FCD7AD;
  --white-color: white;
  --black-color: rgb(21, 21, 21);
  --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

body{
  background-color: #FFFAFB;
}

.heading{
  color: var(--primary-color);
    text-decoration: underline;
    text-decoration-color: #2b2c282e;
}
a{
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  *{
    
  }
}
/* a{
  text-decoration: none;
}

li{
  list-style: none;
}

.flex{
  display: flex;
}

.btn{
  padding: .4rem 1.5rem;
  background: var(--gradient);
  border: none;
  outline: none;
  border-radius: .5rem;
  color: var(--white-color);
}


.btn a{
  color: var(--white-color);
  font-weight: 500;
}

.btn:hover{
  color: var(--primary-color);
}

.btn:hover a{
  color: var(--white-color);
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
