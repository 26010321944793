:root {
    --primary-color: #5296a5;
    --hover-color: rgb(255, 91, 39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient: linear-gradient(rgb(255, 91, 39), rgb(255, 151, 75));
  }

.newsletter{
    padding:5% 12%;
    background: #00DCCF;
    color: white;
    margin: 1rem 0rem;
}

.newsletter-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    h4{
        align-items: flex-start;
    }
   
}


.email-container input{
    padding: 0.5rem 15rem 0.5rem 0.5rem;
    border-radius: 5px;
    border: none;
    margin-right: .5rem;
}

.email-container .btn-1{
    border: .1rem solid rgb(255, 91, 39);
    border-radius: 5px;
    color: white;
    padding: 0.5rem 1rem;
    background: var(--gradient);
    text-align: center;
}

.btn-1:hover{
    border: .1rem solid rgb(255, 91, 39);
    background: none;
    color: rgb(255, 91, 39);
}

@media screen and (max-width: 1024px){
    .newsletter{
        padding: 2rem 2rem;
    }

    .newsletter-content{
        justify-content: center;
        align-items: center;

        .email-container{
            display: flex;
            justify-content: center;
            /* align-items: center; */
            
        }
        
    }
    

    .email-container input{
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        
        border: none;
        margin-right: .5rem;
        /* border-radius: 4px; */
    }
    /* .email-container .btn-1{
        border: none;
        border-radius: 5px;
        color: white;
        padding: .5rem .5rem;
        background: var(--gradient);
        text-align: center;
    } */
}