:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.about-title{
    text-align: center;
    padding: 4rem 6rem;
}
.about-title h2{
    color: var(--hover-color);
}
.about-title p{
         -webkit-line-clamp: 15;
}
.about-title span{
    color: rgb(152, 152, 152);
}

.about-container{
    padding: 1rem 6rem;
}
.upper{
    width: 70%;
    padding-bottom: 1rem;
}
.about-content{
    padding-bottom: 0.7rem; 
}
.about-content p{
    padding-bottom: 0.4rem;
    text-align: justify;
}
.lower{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    .about-title{
        
        padding: 4rem 2rem;
    }
    .about-container{
        padding: 0rem 2rem;
    }
    .upper{
        width: 100%;
    }

    .lower{
        flex-direction: column;
    }
    .lower1{
        padding-bottom: 1rem;
    }
}