:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.home {
    /* height: 20%; */
    background: linear-gradient(to bottom right, rgb(125, 226, 209), rgb(234, 234, 234));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    align-items: center;
  }

.Container{
    display: flex;
    padding: 1.5rem 6rem;
    align-items: center;
    
}

.home .Container .homeText{
    text-align: left;
    
    /* padding: 0px 114px 0px 114px; */
}

.homeImage{
    /* width: 50%; */
  box-sizing: border-box;
  /* padding-left: 5%; */
}

.title{
    color: #FB5607;
}
.homeImage img{
    
    width: 80vh;
  height: auto;
}
.subTitle{
    text-align: justify;
    /* -webkit-line-clamp: 0; */
}
@media screen and (max-width: 1024px) {
    .home .Container .homeText{
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: small;
        align-items: center;
        justify-content: space-between;
    }
    .Container{
        flex-direction: column;
        padding: .5rem 2rem;
        align-items: center;
        justify-content: space-between; 
        
    }
   
    /* .title{
        font-size: 20px;
    } */
    
    .homeImage{
        /* width: 50%; */
      box-sizing: border-box;
      /* padding-left: 5%; */
    }
    .homeImage img{
    
        width: 40vh;
      height: auto;
    }
    
}