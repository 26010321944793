:root {
  --primary-color: #5296a5;
  --hover-color: rgb(255, 91, 39);
  --white-color: white;
  --black-color: rgb(21, 21, 21);
  --gradient: linear-gradient(rgb(255, 91, 39), rgb(255, 151, 75));
}

.header {
  padding: 1rem;
  color: white;
}

.top-section {
  display: flex;
  justify-content: space-between;
  padding: 0rem 6rem;
}

.logo img {
  width: 5rem;
  height: 5rem;
}


.searchBar button {
  background: var(--gradient);
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 4px;
}
.searchInput {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 5px;
}

.login > a {
  color: rgb(255, 91, 39);
}
.signup > a {
  color: white;
}

.user-actions .signup {
  /* display: flex; */
  margin-left: 0.5rem;
  background: var(--gradient);
  border: 1px solid rgb(255, 91, 39);

  padding: 0.3rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
.user-actions .login {
  /* display: flex; */
  margin-left: 0.5rem;
  /* background: var(--gradient); */
  /* border: none; */
  padding: 0.3rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  background: none;
  border: 1px solid rgb(255, 91, 39);
}

.signup:hover{
  background: none;
  a{
    color:rgb(255, 91, 39)
  }
}
.login:hover{
  background: var(--gradient);
  a{
    color: white;
  }
}
.navbar {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.35);
  padding: 0.5rem;
}

.navLink {
  color: #2b2c28;
  text-decoration: none;
  margin: 0 2rem;
}

nav .navLink:hover {
  color: #339989;
}

.toggle-navbar {
  display: none;
}
.close-navbar{
  display: none;
}

@media screen and (max-width: 1024px) {
  .navbar {
   
    position: absolute;
    height: max-content;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(219, 219, 219, 0.904);
    transition: 0.5s ease;
    z-index: 1000;

    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
    background: rgba(255, 255, 255);
    /* align-items: flex-start; */

    .navLink {
      padding: 1rem 0;
      color: #6a6c64;
      font-size: 0.9rem;
      font-weight: 400;
      :hover {
        color: var(--primary-color);
      }
    }

    .user-actions {
      display: flex;
      flex-direction: column;
      .btnn {
        margin-top: 1rem;
      }
    }

    .close-navbar {
      display: flex;
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      color: var(--hover-color);
      font-size: 1.5rem;
    }
  }
  .activeNavbar {
    left: 0;
  }
  .top-section {
    display: flex;
    flex-direction: column;
     /* justify-content: space-around; */
     justify-content: space-between;
    padding: 0rem 0.1rem;
  }

  .logo {
    /* padding: .5rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /* padding: 1.5rem .5rem; */
    

  }
  .searchBar{
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
 
  .toggle-navbar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* position: relative; */
    /* left: 6.2rem; */
    color: var(--primary-color);
    font-size: 2rem;
    cursor: pointer;
  }
  .searchInput{
    width: 70%;
  }

 .logo img{
  width: 4rem;
  height: 4rem;
  /* position: relative; */
      /* right: 6.4rem; */
    
 }

}
