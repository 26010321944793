:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: rgb(21, 21, 21);
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
}

.research{
    padding:0.5rem 5.5rem;
}


.somethin{
    /* background: red; */
    padding: 1rem 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}
.research-content {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: rgb(245, 245, 245);
    /* width: 100%; */
    box-shadow: 4px 4px 5px 2px rgba(171, 171, 171, 0.492);
    
}


.heading{
    text-align: center;
}

.research-heading {
    text-align: justify;
    font-size: large;
    color: var(--primary-color);
}

.research-content p{
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.research-content a{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: large;
    color: var(--hover-color);
}
.research-text a:hover{
    font-size: larger;
    text-decoration: underline;
}

.research-text{
    padding: 0.5rem 1rem;
}

.research-content img{
    width: 100%;
    height: 100%;
    /* border-radius: 5px; */
    
}


@media screen and (max-width: 1024px) {
    .research{
        padding:0rem 2rem;
    }
}