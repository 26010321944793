:root{
    --primary-color: #5296A5;
    --hover-color: rgb(255,91,39);
    --white-color: white;
    --black-color: #2B2C28;
    --gradient : linear-gradient(rgb(255,91,39),rgb(255,151,75));
    --footer-color : #0E2A31;
}

.footer{
    background: linear-gradient(to bottom right, rgb(125, 226, 209), rgb(234, 234, 234));;
}
.footer-container{
    padding: 3rem 6rem;
    display: flex;
    align-items: top;
    justify-content: center;
    justify-content: space-between;
}
.location-logo img{
    height: 9rem;
    width: 9rem;
    padding: 0.25rem;
    
}
.location-logo{
    display: flex;
    flex-direction: column ;  
    align-items: center;
    justify-content: center;
}



.heading1{
    color: white;
    /* text-decoration: underline; */
    text-decoration-color: #ffffff85;
}
.contact .contact-container{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-end;
}

.contact-container input{
    /* flex: 1; */
    margin: 0.2rem .2rem;
    border: none;
    padding: .5rem 5rem .5rem .5rem;
    border-radius: 5px;
    /* padding-right: 10rem; */
    width: 100%;
    
}


/* .contact-container .query{
    padding-bottom: 2rem;
} */

.contact-container .btn-1{
    border: .1rem solid rgb(255, 91, 39);
    border-radius: 5px;
    color: white;
    padding: 0.5rem 1rem;
    background: var(--gradient);
    margin-top: .5rem;
    text-align: center;
    /* margin-inline-start: 75%; */
    
}
.follow{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    margin-top: 2rem;
}
.follow-container{
    
    font-size: 1.5rem;
    cursor: pointer;
    /* align-items: left; */
    justify-content: space-between;
    
}
.icon{
    color: var(--primary-color);
    margin-right: 1rem;
}

.icon:hover{
    color: #E8871E;
    /* font-size: 2rem; */
}
.link-parent{
    display: flex;
    flex-direction: column;
  
    text-align: center;
    align-items: center;
    flex: auto;
}
.link-container{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.top-link {
    flex: 0 0 20%; 
    text-align: center; 
  }
.link-container img{

    height: 2rem;
    width: auto;
    cursor: pointer;
    margin: .5rem 0rem;
}


.bottom{
    background: #00DCCF;
}

.bottom p{
    color: white;
    margin-bottom: 0;
    text-align: center;
}
.link{
    
    /* align-items: center; */
    /* padding-bottom: 0.5rem; */
}

@media screen and (max-width: 1024px){
    .footer-container{
        padding: 2rem 2rem;
        justify-content: center;
        /* align-items: flex-start; */
        
        flex-direction: column;

    }
    .location-logo{
        display: flex;
   
        align-items: center;
        justify-content: space-between;
        /* background: red; */
       
    }
    /* .location-logo img{
        height: 5rem;
        width: 5rem;
        padding: 0.25rem;
        
    } */
    
    .location-logo iframe{
        height: 5rem;
        width: 20rem;
        /* align-items: center; */
    }
    .contact{
        display: flex;
        flex-direction: column;
        /* align-items: left; */
        padding: 1.5rem 0rem;
    }
    .contact-container input{
        /* flex: 1; */
        margin: 0.2rem 0rem;
        border: none;
        padding: .5rem;
        border-radius: 5px;
        /* padding-right: 10rem; */
        /* width: 100%; */
        
    }
    .link{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        /* padding-bottom: 0.5rem; */
    }
    .follow{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        margin-top: 1.5rem;
    }
    .social-link{
        padding: 0rem 0.4rem;
    }
    
}