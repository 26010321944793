.donation {
  padding: 0.5rem 6rem;
}
.donation-content {
  border: 2px solid var(--hover-color);
  border-radius: 5px;
  padding: 2rem 5rem;
}
.donation-content input {
  border: none;
  padding: 0.3rem 4rem 0.3rem 0.5rem;
  border-radius: 5px;
  background: rgb(242, 242, 242);
  width: 100%;
}

.donation-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paddingB {
  padding-bottom: 1.5rem;
}
.donation-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donation-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.donation-btn button {
  border: none;
  background: var(--gradient);
  color: white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
.QR img {
  width: 25rem;
}

@media screen and (max-width: 1024px){
    .donation {
        padding: 0.5rem 2rem;
      }

      .donation-form {
        flex-direction: column;
      }
      .donation-content {
        
        padding: 1rem;
      }
      .QR img {
        width: 15rem;
      }
}