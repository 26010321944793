:root {
  --primary-color: #5296a5;
  --hover-color: rgb(255, 91, 39);
  --white-color: white;
  --black-color: rgb(21, 21, 21);
  --gradient: linear-gradient(rgb(255, 91, 39), rgb(255, 151, 75));
}

.development {
  padding: 0.5rem 5.5rem;
}

.dev-content {
    background: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 95%;
  padding-bottom: 1rem;

  p {
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.dev-carousel{
    padding: 1.5rem 0.5rem;
}
.dev-text{
    padding: 0rem 1rem;
}
.dev-text p{
  color: black;
}
.dev-text h6{
  color: black;
}
.dev-text p:hover{
  text-decoration: underline;
  cursor: pointer;
}
.dev-text h6:hover{
  color: var(--hover-color);
  cursor: pointer;
}
.dev-content .dev-icon {
  font-size: 6rem;
  color: var(--primary-color);
}

@media screen and (max-width: 1024px) {
    .development{
        padding:0rem 2rem;
    }
}
