.sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #333;
    color: white;
  }
  
  .sidebar-header {
    padding: 20px;
    font-size: 2.5rem;
    text-align: center;
    color: coral;
    font-weight: 600;
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-menu li {
    padding: 10px 20px;
  }
  
  .sidebar-menu li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar-menu li a:hover {
    background-color: #555;
  padding: 12px 20px;
  margin-left: -10px; 
  margin-right: -10px;
  }
  
  .logout-btn-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .logout-btn {
    background-color: #555;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .logout-btn:hover {
    background-color: #777;
  }