.signup-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: #f3f4f6;
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
  }
  
  .form {
    margin-top: 32px;
    space-between: 24px;
  }
  
  .input-container {
    margin-bottom: 16px;
  }
  
  .input-label {
    display: block;
    margin-bottom: 8px;
    color: #1f2937;
    font-size: 14px;
  }
  
  .input-field {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    color: #374151;
    background-color: #ffffff;
    outline: none;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .input-field:focus {
    border-color: #4f46e5;
    box-shadow: 0 0 0 1px #4f46e5;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #4f46e5;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .submit-button:hover {
    background-color: #4338ca;
  }
  
  .submit-button:focus {
    outline: 2px solid #4f46e5;
    outline-offset: 2px;
  }
  